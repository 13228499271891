import React, { useEffect } from "react";
import { useJitsi } from "react-jutsu";
import styled from "styled-components";

const CallContainer = styled.div`
    width: 100%;
    #call-container {
        width: 100%;
        height: 100vh;
    }
`;

const Call = (props) => {
    const parentNode = "call-container";

    const interfaceConfig = {
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        DEFAULT_REMOTE_DISPLAY_NAME: "Fellow human",
        DEFAULT_LOCAL_DISPLAY_NAME: "me",
        SHOW_CHROME_EXTENSION_BANNER: false,
        TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            "closedcaptions",
            "desktop",
            "fullscreen",
            "fodeviceselection",
            "hangup",
            "profile",
            // "info",
            "chat",
            // "recording",
            // "livestreaming",
            "etherpad",
            "sharedvideo",
            "settings",
            // "raisehand",
            "videoquality",
            "filmstrip",
            "invite",
            "feedback",
            "stats",
            "shortcuts",
            "tileview",
            "videobackgroundblur",
            "download",
            "help",
            "mute-everyone",
            "e2ee",
        ],
    };

    const options = {
        roomName: props.roomName,
        parentNode,
        height: "100%",
        width: "100%",
        interfaceConfigOverwrite: interfaceConfig,
    };

    const jitsi = useJitsi(options);

    useEffect(() => {
        if (jitsi) {
            jitsi.addEventListener("videoConferenceJoined", () => {
                jitsi.executeCommand("displayName", props.displayName);
                jitsi.executeCommand("password", props.password);
                jitsi.executeCommand("subject", props.subject);
            });

            jitsi.addEventListener("videoConferenceLeft", () => {
                props.leftCall();
            });
        }
        return () => jitsi && jitsi.dispose();
    }, [jitsi, props]);

    return (
        <CallContainer>
            <div id={parentNode} />
        </CallContainer>
    );
};

export default Call;
