import React from "react";
import "./App.css";
import Wrapper from "./Components/Wrapper";

class App extends React.Component {
    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <Wrapper />
                </header>
            </div>
        );
    }
}

export default App;
